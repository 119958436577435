<template>
  <div class="bg-white">
    <a-tabs type="card" :tabBarGutter="10">
      <a-tab-pane v-for="item in tabList" :key="item.id" :tab="item.name">
        <component :is="item.component"></component>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import {
  HomeAdvert,
  OpenAdvert,
  // MallAdvert,
  // ShopAdvert,
  // NewsAdvert,
  // GiftCardAdvert,
} from "./adverts"
export default {
  data() {
    return {
      tabList: [
        {
          id: 1,
          name: "PC网站首页图",
          component: OpenAdvert,
        },
        {
          id: 2,
          name: "小程序/手机网站首页图",
          component: HomeAdvert,
        },
        // {
        //   id: 3,
        //   name: "首页NEWS",
        //   component: NewsAdvert,
        // },
        // {
        //   id: 4,
        //   name: "门店页广告",
        //   component: ShopAdvert,
        // },
        // {
        //   id: 5,
        //   name: "商城页广告",
        //   component: MallAdvert,
        // },
        // {
        //   id: 6,
        //   name: "个人页礼品卡",
        //   component: GiftCardAdvert,
        // },
      ],
    }
  },
  methods: {
    callback(key) {
      console.log(key)
    },
  },
}
</script>
<style>

.card-container {
  background: #f5f5f5;
  overflow: hidden;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>