<template>
  <div class="p-10">
    <div class="flex items-center">
      <div style="width:80px;">
        <span style="color: #f5222d;" class="mr-1">*</span>跑马灯：
      </div>
      <a-input v-model="formState.notice"></a-input>
    </div>
    <a-divider />

    <div>
      <div>
        <div class="mt-5" v-for="(item,i) in advertList" ref="searchRef" :key="i">
          <a-row>
            <a-col :span="8" class="flex">
              <div style="width:80px;">
                <span style="color: #f5222d;" class="mr-1">*</span>图片：
              </div>
              <upload-image :type="isShop? '11': '10'" @remove="handlerRemove(i)" :value.sync="item.image_path" />
            </a-col>
            <a-col :span="16" class="flex">
              <span style="color: #f5222d;" class="mr-1 mt-1">*</span>
              <span class="mt-1">跳转：</span>
              <jump-type type="2" :data="item" />
            </a-col>
          </a-row>
        </div>
      </div>
      <a-button
        v-if="advertList.length < 8"
        type="primary"
        @click="handlerAdd"
        style="margin-left: 80px;"
      >新增广告图</a-button>
    </div>
    <a-divider />
    <div style="text-align:center;">
      <!-- <a-button>取消</a-button> -->
      <a-button type="primary" @click="onSubmit" style="margin:0 10px;">保存</a-button>
    </div>
  </div>
</template>

<script>
import UploadImage from "@/components/upload-image"
import JumpType from "../components/jump-type.vue"
import { getShopAdvertList, editShopAdvert } from "@/api/advert"
import { getAdvertList, editAdverts } from "@/api/shop"
import { requiredRule } from "@/hooks/use-form-rules"
export default {
  components: { UploadImage, JumpType },
  props: {
    isShop: {
      type: Boolean,
      defalut: false,
    },
  },
  data() {
    return {
      requiredRule: requiredRule,
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      typeList: [],
      advertList: [],
      formState: { notice: "" },
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async initData() {
      if (this.isShop) {
        const { data, code } = await getAdvertList()
        if (code === 0) {
          this.advertList = data.list
          this.formState.notice = data.notice
          if (data.list.length == 0) {
            this.advertList.push(
              {
                id: 0,
                sort: 2,
                image_path: "",
                edit_jump_type: 0,
                edit_jump_data: "",
              },
              {
                id: 0,
                sort: 2,
                image_path: "",
                edit_jump_type: 0,
                edit_jump_data: [],
              },
            )
          }
        }
      } else {
        const { data, code } = await getShopAdvertList()
        if (code === 0) {
          this.advertList = data.list
          this.formState.notice = data.notice
        }
      }
    },

    onSubmit() {
      if (this.isShop) {
        editAdverts({
          notice: this.formState.notice,
          data: JSON.stringify(this.advertList),
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("保存成功！")
          }
        })
      } else {
        editShopAdvert({
          notice: this.formState.notice,
          data: JSON.stringify(this.advertList),
        }).then((res) => {
          if (res.code === 0) {
            this.$message.success("保存成功！")
          }
        })
      }
    },

    // 新增
    handlerAdd() {
      if (this.advertList.length < 8) {
        this.advertList.push({
          edit_jump_data: { url: "" },
          edit_jump_type: 0,
          id: 0,
          image_path: "",
          sort: 1,
          title: "",
        })
      }
    },

    handlerRemove(index) {
      this.advertList.splice(index, 1)
    },
  },
}
</script>

<style lang="less">
.advert-title {
  position: absolute;
  top: 7px;
  left: 60px;
  font-weight: 600;
  font-size: 16px;
}
.border-b-1 {
  position: relative;
  border-bottom: 1px solid rgb(194, 194, 194);
  margin-bottom: 20px;
}
</style>