import { render, staticRenderFns } from "./giftcard-advert.vue?vue&type=template&id=5447476d&scoped=true&"
import script from "./giftcard-advert.vue?vue&type=script&lang=js&"
export * from "./giftcard-advert.vue?vue&type=script&lang=js&"
import style0 from "./giftcard-advert.vue?vue&type=style&index=0&id=5447476d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5447476d",
  null
  
)

export default component.exports