<template>
  <div class="flex">
    <!-- <a-cascader :options="typeList"
                :loadData="initGoodsData"
                change-on-select
                placeholder="请选择商品"
    @change="onChange" />-->
    <a-select
      style="width: 150px; margin-right:20px"
      v-model="data.edit_jump_data.type_id"
      :options="typeList"
      @change="handleChange"
      placeholder="请选择分类"
    ></a-select>
    <a-select
      style="width: 150px"
      v-model="data.edit_jump_data.goods_id"
      :options="goodsList"
      placeholder="请选择商品"
    ></a-select>
  </div>
</template>

<script>
import { getMallTypeList, getMallGoodsList } from "@/api/mall"

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      typeList: [],
      goodsList: [],
    }
  },
  mounted() {
    this.initTypeData()
  },
  methods: {
    async initTypeData() {
      const { data, code } = await getMallTypeList()
      if (code === 0) {
        this.typeList = data.list.map((item) => {
          return {
            label: item.type_name,
            value: item.type_id,
            isLeaf: false,
          }
        })
        if (this.data.edit_jump_data.type_id) {
          this.initGoodsData(this.data.edit_jump_data.type_id)
        }
      }
    },
    async initGoodsData(id) {
      const { data, code } = await getMallGoodsList({ type_id: id })
      if (code === 0) {
        this.goodsList = data.list.map((item) => {
          return {
            label: item.goods_name,
            value: item.goods_id,
          }
        })
      }
    },
    handleChange(e) {
      this.data.edit_jump_data.goods_id = null
      this.initGoodsData(e)
    },
  },
}
</script>

<style>
</style>