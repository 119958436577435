<template>
  <div class="flex">
    <a-select style="width: 150px; margin-right:20px;"
              v-model="data.edit_jump_data.type_id"
              :options="typeList"
              @change="handleChange"
              placeholder="请选择分类">
    </a-select>
    <a-select v-if="typeSubList.length>0"
              style="width: 150px; margin-right:20px;"
              v-model="data.edit_jump_data.son_type_id"
              :options="typeSubList"
              @change="handleSubChange"
              placeholder="请选择子分类">
    </a-select>
    <a-select style="width: 150px; margin-right:20px;"
              v-model="data.edit_jump_data.goods_id"
              :options="goodsList"
              placeholder="请选择商品" @change="handlerGoodsChange">
    </a-select>
  </div>
</template>

<script>
import { getTypeList, getSubTypeList, getGoodsListByTypeId } from '@/api/shop'

export default {
  props: {
    data: {
      type: Object,
    },
  },
  data () {
    return {
      typeList: [],
      typeSubList: [],
      goodsList: [],
    }
  },
  mounted () {
    this.initTypeList()
  },
  methods: {
    async initTypeList () {
      const { data, code } = await getTypeList()
      if (code === 0) {
        this.typeList = data.list.map(item => {
          return {
            label: item.type_name,
            value: item.type_id,
            isLeaf: false
          }
        })
        if (this.data.edit_jump_data.type_id) {
          this.initTypeSubList(this.data.edit_jump_data.type_id)
        }
      }
    },
    async initTypeSubList (id) {
      const { data, code } = await getSubTypeList({
        parent_type_id: id
      })
      if (code === 0) {
        this.typeSubList = data.list.map(item => {
          return {
            label: item.type_name,
            value: item.type_id,
            isLeaf: false
          }
        })
        if (this.data.edit_jump_data.son_type_id) {
          this.initGoodsData(this.data.edit_jump_data.son_type_id)
        }
      }
    },
    async initGoodsData (id) {

      const { data, code } = await getGoodsListByTypeId({ type_id: id })
      if (code === 0) {
        this.goodsList = data.list.map(item => {
          return {
            label: item.goods_name,
            value: item.goods_id
          }
        })
      }
    },
    handleChange (e) {
      this.data.edit_jump_data.type_id = e
      this.data.edit_jump_data.son_type_id = ''
      this.data.edit_jump_data.goods_id = ''

      this.initTypeSubList(e)
    },
    handleSubChange (e) {
      this.data.edit_jump_data.son_type_id = e
      this.data.edit_jump_data.goods_id = ''

      this.initGoodsData(e)
    },
    handlerGoodsChange(e){
      this.data.edit_jump_data.goods_id = e
    }
  },
}
</script>

<style>
</style>